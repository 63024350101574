import React from "react"


export default function Services() {
  return (
    <>
          <section id="why-us">
            <div className="row row-eq-height justify-content-center" id="why-sterilwize">
              <div>
                <header className="section-header">
                  <h3>Services for Large Companies</h3> 
                </header>
                <br></br>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card1" data-aos="zoom-in" data-aos-delay="200">
                  <i class="bi bi-bounding-box icon-center" style={{color:"red"}}></i>
                  <div className="card-body">
                    <h5 className="card-title">Strategic Consultation & Technology Assessment</h5>
                    <p className="card-text">
                      Investigate and evaluate the existing operations & technology based on your goals.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card1" data-aos="zoom-in" data-aos-delay="200">
                  <i className="bi bi-easel icon-center" style={{color:"red"}}></i>

                  <div className="card-body">
                    <h5 className="card-title">Technology Plan</h5>
                    <p className="card-text">
                      Develop new technology, improve existing one or integrate both to implement your business and operational goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card1" data-aos="zoom-in" data-aos-delay="200">
                  <i class="bi bi-cpu-fill icon-center" style={{color:"red"}}></i>
                  <div className="card-body">
                    <h5 className="card-title">Technology Development</h5>
                    <p className="card-text">
                      Create practical, cost effective, custom technology to improve and optimize operations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-4">
                <div className="card1" data-aos="zoom-in" data-aos-delay="200">
                  <i class="bi bi-building icon-center" style={{color:"red"}}></i>
                  <div className="card-body">
                    <h5 className="card-title">Technology Integration, Implementation, and Training</h5>
                    <p className="card-text">
                      Ensure a smooth transition by running new applications parallel to legacy technology and training the staff for adoption.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card1" data-aos="zoom-in" data-aos-delay="200">
                  <i className="bi bi-binoculars-fill icon-center"style={{color:"red"}}></i>
                  <div className="card-body">
                    <h5 className="card-title">Monitoring & Ongoing Support</h5>
                    <p className="card-text">
                      Monitor the efficiency of new technology and provide monthly reports on the parameters and metrics of your choice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
       
          </>
  )
}
